@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans 3", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.img-round-10 {
  border-radius: 10%;
}

.img-round-20 {
  border-radius: 20%;
}

.img-round-30 {
  border-radius: 30%;
}

.img-round-40 {
  border-radius: 40%;
}

.img-round-50 {
  border-radius: 50%;
}

.map-icon {
  height: 30px;
  padding-bottom: 10px;
}

.label-last-location {
  margin-left: -50px;
  font-size: 10px;
  border-radius: 20%;
  padding-left: 2px;
  padding-right: 2px;
}

.label-last-location-on {
  background-color: #18c54c;
}

.label-last-location-off {
  background-color: #d0d1d5;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track {
  background: #555;
}
